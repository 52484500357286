.root {
  width: 100%;
  height: 100%;
}

.link {
  display: block;
  border-radius: 10px;
  height: 100%;
  border: 1px solid var(--border-color);
  padding: 20px 16px;

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    font-weight: normal;
  }

  &:hover {
    border-color: var(--border-hover-color);
  }

  &:active {
    border-color: var(--border-active-color);
  }
}

.noLink {
  display: block;
  border-radius: 10px;
  height: 100%;
  border: 1px solid var(--border-color);
  padding: 20px 16px;
}

.details {
  padding: 20px 0 0;
}

.name {
  color: var(--title-color);
  font-size: var(--font-size-16px, 16px);
  line-height: var(--line-height-for-16px);
  font-weight: var(--font-weight-bold);
  margin-bottom: 2px;
  letter-spacing: -0.2px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.views {
  color: var(--text-lighter-color);
  font-size: var(--font-size-14px, 14px);
  line-height: var(--line-height-for-14px);
  font-weight: var(--font-weight-regular);
  letter-spacing: -0.5px;
  text-align: center;
}

.profilePicture {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 5px;
}

.fallbackProfilePictureImg {
  visibility: hidden;
}

.fallbackImgSkeleton {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
